import React from "react";
import { Helmet } from "react-helmet";
import SEO from "../components/seo";
import styled, { createGlobalStyle } from "styled-components";

// const summary = `Freelance Web Developer with 15+ years experience in the web industry,
// having worked on a wide range of projects from small bespoke sites to
// building sites for large businesses from start to finish.`;

// const summary = `
// 15+ years experience in the web industry crafting beautiful and functional websites in React, Javascript and CSS.
// Expert in transforming designs into fully functional layouts that works across a range of devices.
// `;

const role = "Frontend developer";
// const role = "Software engineer";

const summary = `
${role} specialising in React, Typescript and CSS.
15+ years experience crafting beautiful and functional web experiences.

`;
// Expert in transforming designs into fully responsive layouts.

// const techSkills = [
//   "React, Next.js, Gatsby, Redux, React Native, Vue.js",
//   "CSS, SASS, Styled Components, Bootstrap, Material UI",
//   "Javascript, Typescript, ES6, Webpack",
//   "React Spring, Greensock, Three.js",
//   "Apollo, GraphQL, consuming REST APIs",
//   "Jest, Enzyme",
//   "Git, Storybook, Agile",
//   "Drupal, Contentful",
// ];

// const techSkills = [
//   "React, React hooks",
//   "Next.js, Gatsby, Redux, Vue.js",
//   "CSS, SASS, Tailwind CSS, Styled Components, Material UI, Bootstrap",
//   "Javascript, ES6, Typescript",
//   "HTML, HTML5, semantic markup",
//   "Greensock",
//   "Apollo, GraphQL, consuming REST APIs",
//   "Jest, React Testing Library, Enzyme",
//   "Git, Storybook, Agile",
// ];

const techSkills = [
  "React, Vue.js",
  "Typescript, ES6, Javascript, Node.js",
  "Next.js, Gatsby, Redux",
  "CSS, SASS, Styled Components, Emotion",
  "Tailwind, MUI, Bootstrap",
  "HTML, HTML5, semantic markup",
  "Greensock",
  "React Query, Apollo, GraphQL, consuming REST APIs",
  "Jest, React Testing Library, Enzyme, Playwright",
  // "PHP, Laravel, MySQL",
  // "Git, Storybook", //, Agile",
  "Flutter, Dart",
];

const PersonalSkills = [
  "Strong attention to detail",
  "Highly organised in fast paced environments",
  "Pragmatic problem solving skills",
  "Conscientious and enthusiastic team player with a positive approach",
  "Self motivated, quick to learn new skills",
];

const education = [
  {
    school: "Victoria University of Wellington",
    // date: "1999 – 2002",
    items: [
      {
        title: "Bachelor of Science", // (BSc)",
        major: "Major in Computer Science",
      },
      {
        title: "Bachelor of Commerce and Administration",
        major: "Major in E-commerce",
      },
    ],
  },
  {
    school: "Yoobee School of Design",
    // date: "2003 – 2004",
    items: [{ title: "Diploma of Graphic Design" }],
  },
];

const experience = [
  {
    name: "Nui Markets",
    title: "Senior Software Engineer",
    date: "Nov 2023 – present",
    items: [
      {
        job: "Digital Trading Platform",
        tech: "React, Typescript, CSS, Flutter, Dart",
        desc:
          "Web app for large scale trading of dairy products between businesses",
      },
      {
        desc:
          "Developed cross-platform app in Flutter, using a single codebase to create apps for iOS, Android and web.",
      },
      {
        desc: "Mentor junior developers, code reviews",
      },
    ],
  },
  {
    name: "Atlassian",
    title: "Software Engineer",
    date: "Jan 2022 – Nov 2023",
    items: [
      {
        job: "Atlassian Editor Platform",
        tech: "React, Typescript, React Testing Library, Prosemirror",
        desc:
          "Maintain and improve the Atlassian Editor platform, a rich text editor used across all Atlassian products including Jira, Confluence, Trello and Bitbucket",
      },
      {
        desc:
          "Lead projects from start to finish, working closely with designers and product managers to deliver high quality features, including bringing generative AI to the Editor platform",
      },
      // copy button, toolbar overflow
      // AI nested experience stream (modal)
      {
        desc: "Code reviews, pair programming",
      },
    ],
  },
  {
    name: "Datacom",
    title: "Senior Frontend Developer",
    date: "Nov 2021 – Jan 2022 (contract)",
    items: [
      {
        job: "Farmlands Card Partners",
        tech: "HTML, Javascript, CSS, Google Maps API",
        desc:
          "Frontend build of a card partners locator for Farmlands, allow users to search and filter card partners and display results on a map",
      },
    ],
  },
  {
    name: "Pixel Fusion",
    title: "Senior Frontend Developer",
    date: "Nov 2020 – Nov 2021 (contract)",
    items: [
      {
        job: "The Mind Lab",
        tech: "React, Next.js, Tailwind CSS, Typescript, Apollo, GraphQL",
        desc:
          "Frontend build of a learning management system. Features include authentication, quizes, drag and drop file upload, submit assignments for marking, commenting system, notifications",
      },
      {
        job: "Allpress Espresso",
        tech: `React, Gatsby, Typescript, Styled Components, GraphQL, Google Maps API, Algolia, 
Contentful, Shopify`,
        desc:
          "Frontend rebuild of the existing customer-facing website, including a store locator to help users search and filter results on a map",
      },
    ],
  },
  {
    name: "Nintex",
    title: "Senior Frontend Developer",
    date: "Sep - Nov 2020 (contract)",
    items: [
      {
        job: "Promapp",
        tech: "React, SASS, Typescript",
        desc:
          "Retheme of the existing cloud-based business process web app, unifying styles to bring inline with the global product style guide",
      },
    ],
  },
  {
    name: "Osynlig",
    title: "Senior Frontend Developer",
    date: "Apr – May 2020 (contract)",
    items: [
      {
        job: "Wallabies Travel",
        tech: "React, Next.js, Styled Components, Apollo, GraphQL",
        desc: "Frontend build of website and booking form",
      },
    ],
    breakBefore: true,
  },
  {
    name: "Isobar",
    title: "Senior Frontend Developer",
    date: "Jan – Apr 2020 (contract)",
    items: [
      {
        job: "AJ Hackett / skyparkglobal.com",
        tech: "React, Next.js, Styled Components, Apollo, GraphQL",
        desc:
          "Frontend rebuild of AJ Hackett’s network of international sites. Implementation of a multi-lingual booking system",
      },
    ],
  },
  {
    name: "Concept & Code",
    title: "Freelance Frontend Developer",
    date: "Jul 2016 – present (self-employed)",
    items: [
      {
        job: "Fisher Funds Online",
        tech: "React, Redux, Typescript, MUI",
        desc:
          "Web app for customers to track and manage their investments. Implemented new sections with MUI",
      },
      {
        job: "Geoinsight - Remote HQ",
        tech: "React, Redux, Styled Components",
        desc:
          "Web app for tracking NZ forestry activities. Implemented new features, performance optimisations",
        // web app that tracks nz forestry activities
        // there is a live map
        // users can search and filter forestry activities
      },

      // {
      //   job: "Woolworths Listens",
      //   tech: "Vue.js, Vuetify, Nuxt, Tailwind",
      // },
      // {
      //   job: "Covid19map NZ",
      //   tech: "React, Next.js, Typescript, Styled Components, Node.js",
      //   desc: `Created New Zealand’s first live map and infographics of Covid-19 cases`,
      // },
      {
        job: "Dairy NZ - The Long Game",
        // https://www.dairynz.co.nz/thelonggame
        tech: "React, Typescript, CSS Modules, Greensock",
      },
      {
        job: "Samsung - trygalaxy.com",
        tech: "React, Next.js, Typescript, Tailwind CSS, Greensock",
      },
      {
        job: "Realestate.co.nz",
        tech: "Ember, Tailwind CSS",
        desc: `Rebuilt the entire frontend of New Zealand’s largest real estate website`,
      },
      {
        job: "Mazda New Zealand",
        tech: "Vue.js, SASS, Drupal",
        desc: "Complete retheme of the Mazda New Zealand website",
      },
      // {
      //   job: "Expenses app",
      //   tech: "React Native, Redux, Typescript, Contentful, Node.js",
      //   desc:
      //     "iOS and Android app for recording expenses, uploads data to Contentful",
      // },
      // {
      //   job: "TVNZ Antarctica / undertheice.tvnz.co.nz",
      //   tech: "React, SASS",
      //   desc:
      //     "Interface development for Answers Under the Ice, an immersive exploration of Antarctica",
      // },
      // {
      //   job: "Other projects",
      //   desc:
      //     "Realestate.co.nz, Mazda New Zealand, TVNZ Antarctica, Fisher Funds",
      // },
    ],
  },
  // {
  //   name: "Accenture Interactive",
  //   title: "Senior Frontend Developer",
  //   date: "Mar 2015 – Jul 2016",
  //   items: [
  //     {
  //       job: "Warehouse Money",
  //       tech: "Angular, Bootstrap, SASS",
  //       desc:
  //         "Frontend build of the Warehouse Money website. Built the credit card application form in Angular",
  //     },
  //     {
  //       job: "Australian Brandenburg Orchestra",
  //       tech: "Bootstrap, SASS, Jquery",
  //       desc:
  //         "Frontend build, integration with an Umbraco backend. Awarded Webby Official Honoree for Best User Interface",
  //     },
  //   ],
  // },
  // {
  //   name: "Reactive",
  //   title: "Senior Web Developer",
  //   date: "Jun 2013 – Mar 2015",
  //   items: [
  //     {
  //       job: "Projects",
  //       desc: "Nissan Australia, Cervelo",
  //     },
  //   ],
  // },
  // {
  //   name: "Sparks Interactive",
  //   title: "Senior Web Developer",
  //   date: "Jun 2008 – Jun 2013",
  //   items: [
  //     {
  //       job: "Tourism Fiji",
  //       tech: "Drupal, Jquery, SASS",
  //       desc:
  //         "Frontend theming with a Drupal backend. Developed a drag-and-drop interface for the itinerary builder",
  //     },
  //     {
  //       job: "Other projects",
  //       desc:
  //         "National Business Review, Oxfam New Zealand, Ronald McDonald House Charities",
  //       // , International Volunteer HQ",
  //     },
  //   ],
  // },
];

export const CVStyle = createGlobalStyle`
  @page {
    background: #e3e2dd;
    size: A4;
    margin: 7mm;
    /* @bottom-right {
       content: counter(page) " / " counter(pages);
    } */
  }
  body {
    background: #e3e2dd;
  }
`;

const StyledCV = styled.div`
  color: #496447;
  padding: 0.6cm 0.8cm 0;
  font-family: "Montserrat", sans-serif;
  font-size: 8pt;
  line-height: 13pt;
  h1 {
    margin: 0 0 0.3cm;
    font-size: 10pt;
  }
  h2 {
    margin: 0 0 0.3cm;
    font-size: 13pt;
  }
  h3 {
    font-size: 12pt;
    margin: 0 0 0.5cm;
    :not(:first-child) {
      margin-top: 1cm;
    }
  }
  h4 {
    margin: 0 0 0.5mm;
    font-size: 9pt;
    font-weight: 600;
    span {
      margin: 0 0.07cm;
    }
  }
  h5 {
    margin: 0 0 1mm;
    font-size: 9pt;
    font-weight: 600;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  p {
    margin: 0 0 1cm;
    font-size: 11pt;
    line-height: 16pt;
    font-weight: 600;
  }
  ul {
    margin-top: 1mm;
    padding-left: 4mm;
  }
  li {
    margin-bottom: 1mm;
  }
  strong {
    font-weight: 600;
  }
  header {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding-bottom: 0.7cm;
    margin-bottom: 0.7cm;
    border-bottom: solid 0.1mm currentColor;
    font-size: 9pt;
    line-height: 15pt;
  }
  .right {
    text-align: right;
  }
  main {
    display: grid;
    grid-template-columns: 0.6fr 1fr;
    grid-gap: 1cm;
  }
`;

const StyledExperience = styled.div`
  white-space: "pre-wrap";
  @media print {
    page-break-before: ${(props) => (props.breakBefore ? "always" : "initial")};
    margin-top: ${(props) => (props.breakBefore ? "3em" : "0")};
  }
`;

const CV = () => (
  <StyledCV>
    <CVStyle />
    <SEO
      title="CV"
      meta={[
        {
          name: "robots",
          content: "noindex, nofollow",
        },
      ]}
    />

    <Helmet>
      <link
        href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap"
        rel="stylesheet"
      />
    </Helmet>

    <header>
      <div className="right">
        <h1>{role}</h1>
        Auckland, New Zealand
        <br />
        <a href="mailto:mail@dixoncheng.com">mail@dixoncheng.com</a>
        {/*<br />
        <a href="tel:0211103335">021 110 3335</a>
    <br />*/}
      </div>
      <div>
        <h2>Dixon Cheng</h2>
        <a
          href="https://dixoncheng.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          dixoncheng.com
        </a>
        <br />
        <a
          href="https://github.com/dixoncheng"
          target="_blank"
          rel="noopener noreferrer"
        >
          github.com/dixoncheng
        </a>
        <br />
        <a
          href="https://www.linkedin.com/in/dixon-cheng/"
          target="_blank"
          rel="noopener noreferrer"
        >
          linkedin.com/in/dixon-cheng
        </a>
      </div>
    </header>
    <main>
      <div>
        <p>{summary}</p>
        <h3>KEY SKILLS</h3>
        <h4>Technical Skills</h4>
        <ul>
          {techSkills.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
        {/* <h4>Personal Attributes</h4>
        <ul>
          {PersonalSkills.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul> */}
        <h3>EDUCATION</h3>
        {education.map(({ school, date, items }) => (
          <div key={school}>
            <h4>{school}</h4>
            {date}
            <ul>
              {items.map(({ title, major }) => (
                <li key={title}>
                  {title}
                  {major && (
                    <>
                      <br />
                      {major}
                    </>
                  )}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <div>
        <h3>EXPERIENCE</h3>
        {experience.map(({ name, title, date, items, breakBefore }) => (
          <StyledExperience key={name} breakBefore={breakBefore}>
            <h4>
              {name} <span>/</span> {title}
            </h4>
            {date}
            <ul>
              {items &&
                items.map(({ job, tech, desc }) => (
                  <li key={job}>
                    {job && <strong>{job}</strong>}

                    {tech && (
                      <>
                        <br />
                        <em>{tech}</em>
                      </>
                    )}
                    {desc && (
                      <>
                        {(job || tech) && <br />}
                        {desc}
                      </>
                    )}
                  </li>
                ))}
            </ul>
          </StyledExperience>
        ))}
      </div>
    </main>
  </StyledCV>
);

export default CV;
